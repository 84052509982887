import * as Yup from 'yup';
import { boolean } from 'yup';
import cron from 'cron-validate';
import { backupScheduleCronOptions, enterpriseCronOptions } from 'app/constants/cron';
import { MemoryEnum } from '../constants';
import { ApplicationDto, EnvVar, StorageDto } from '../openapi';

export const nameRegExp = /^[a-z]([a-z0-9.-]*[a-z0-9])?$/;
export const urlRegExp =
  /^((https?|ftp):\/\/)?([^\s:@]+(:[^\s:@]*)?@)?([^\s:@]+)(:[0-9]+)?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/;
export const imageTagRegExp = /^(\d+)\.(\d+)(\.\d+)?$/;
export const noUpperCaseRegExp = /^[^A-Z]*$/;
export const userNameRegExp = /^[a-z][a-z0-9]*$/;

const envVarRegExp = /^[\x20-\x7E\x80-\xFF]*$/;

// https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-cpu

const cpuResourcesRegExp = /^[0-9]+m$|^[0-9]+\.([0-9]{1,3})$|^[0-9]+$/;

const milliCpuRegExp = /^[0-9]+m$/;

// https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-memory

const unitSize = {
  [MemoryEnum.Ei]: 2 ** 60,
  [MemoryEnum.Pi]: 2 ** 50,
  [MemoryEnum.Ti]: 2 ** 40,
  [MemoryEnum.Gi]: 2 ** 30,
  [MemoryEnum.Mi]: 2 ** 20,
  [MemoryEnum.Ki]: 2 ** 10,
};

export const REQUIRED_FIELD_ERROR_MESSAGE = 'Field is required';
export const VALIDATION_FIELD_DEFAULT_MESSAGE = `Field is not valid. It must contain only lowercase alphanumeric characters (a-z) or '-', or '.', and must start with an alphanumeric character.`;

export const envVarsArrSchema = Yup.array()
  .of(
    Yup.object({
      name: Yup.string().matches(envVarRegExp, 'Variable name is not valid').required('Variable name is required'),
      value: Yup.string().matches(envVarRegExp, 'Variable value is not valid'),
      valueFrom: Yup.object()
        .shape({
          secretKeyRef: Yup.object().shape({
            name: Yup.string()
              .test('secret value validation', 'Source value is required', (_, context) => !!context.originalValue)
              .matches(envVarRegExp, 'Variable secret name is not valid'),
            key: Yup.string()
              .test('secret key validation', 'Source key is required', (_, context) => !!context.originalValue)
              .matches(envVarRegExp, 'Variable secret key is not valid'),
            optional: Yup.boolean(),
          }),
        })
        .nullable(),
    }),
  )
  .test('unique names validation', 'Duplicate variable names are not allowed', (values, context) => {
    const envNames = values?.map((env) => env.name) || [];
    const postgresEnvNames = context.parent?.postgresEnvVars?.map((env) => env.name) || [];

    const resEnvVars = postgresEnvNames.length > 0 ? values?.concat(context.parent.postgresEnvVars) : values;
    const resEnvNames = postgresEnvNames.length > 0 ? [...envNames, ...postgresEnvNames] : envNames;

    return resEnvVars?.length === new Set(resEnvNames).size;
  });

export const envVarsSchema: Yup.ObjectSchema<{ envVars?: EnvVar[] }> = Yup.object().shape({
  envVars: envVarsArrSchema,
});

const memorySchemaFn = (msg: string) =>
  Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_FIELD_ERROR_MESSAGE)
      .test('memory validation for requested value', msg, function (_, context) {
        const limitSize =
          parseFloat(context?.from?.[1]?.value.memoryLimit?.value) *
          unitSize[context?.from?.[1]?.value.memoryLimit?.unit];
        const requestSize =
          parseFloat(context?.from?.[1]?.value.memoryRequested?.value) *
          unitSize[context?.from?.[1]?.value.memoryRequested?.unit];

        return limitSize >= requestSize;
      }),
  });

const cpuSchemaFn = (msg: string) =>
  Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .matches(cpuResourcesRegExp, 'Invalid format')
    .test('CPU validation for requested value', msg, function (_, context) {
      const cpuRequested = context?.from?.[1].value.resources.cpuRequested;
      const cpuLimit = context?.from?.[1].value.resources.cpuLimit;
      const requestSize = milliCpuRegExp.test(cpuRequested)
        ? +cpuRequested.replace('m', '')
        : parseFloat(cpuRequested) * 1000;
      const limitSize = milliCpuRegExp.test(cpuLimit) ? +cpuLimit.replace('m', '') : parseFloat(cpuLimit) * 1000;

      return requestSize <= limitSize;
    });

export const getNameValidation = (entity: string) => {
  return Yup.string()
    .max(30, 'The name should contain less or equal then 30 characters')
    .matches(
      nameRegExp,
      `${entity} name is not valid. Name must consist of lower case alphanumeric characters (a-z) or '-', or '.', and must start with an alphanumeric character`,
    )
    .required(`${entity} name is required`);
};

export const resourcesSchema = Yup.object().shape({
  cpuLimit: cpuSchemaFn('CPU limit should be more or equal then Requested CPU'),
  cpuRequested: cpuSchemaFn('Requested CPU should be less or equal then CPU limit'),
  memoryRequested: memorySchemaFn('Requested memory should be less or equal then memory limit'),
  memoryLimit: memorySchemaFn('Memory limit should be more or equal then requested memory'),
  replicas: Yup.number()
    .integer('The value should be integer')
    .min(1, 'The value should be greater than or equal to 1')
    .required('Replicas field is required'),
});

const createSchema = Yup.object().shape({
  ...envVarsSchema.fields,
  image: Yup.string().required('Image is required'),
  adminPort: Yup.number().required('Admin port is required'),
  storage: Yup.object().shape({
    type: Yup.string().required('Storage is required'),
    user: Yup.string().test('User field should be not empty', REQUIRED_FIELD_ERROR_MESSAGE, function (value, context) {
      if ((context.parent as StorageDto).type !== StorageDto.type.MEMORY && !value) return false;
      return true;
    }),
    password: Yup.string().test(
      'Password field should be not empty',
      REQUIRED_FIELD_ERROR_MESSAGE,
      function (value, context) {
        if ((context.parent as StorageDto).type !== StorageDto.type.MEMORY && !value) return false;
        return true;
      },
    ),
    hostname: Yup.string().test(
      'Hostname field should be not empty',
      REQUIRED_FIELD_ERROR_MESSAGE,
      function (value, context) {
        if ((context.parent as StorageDto).type === StorageDto.type.EXTERNAL && !value) return false;
        return true;
      },
    ),
    port: Yup.string().test('Port field should be not empty', REQUIRED_FIELD_ERROR_MESSAGE, function (value, context) {
      if ((context.parent as StorageDto).type === StorageDto.type.EXTERNAL && !value) return false;
      return true;
    }),
    // during validation the size should be the next format and when we send it to
    // server change to string
    size: Yup.object().shape({
      value: Yup.number(),
      unit: Yup.string(),
    }),
    backupSize: Yup.object().shape({
      value: Yup.number(),
      unit: Yup.string(),
    }),
  }),
  resources: resourcesSchema,
});

const createRemoteSchema = Yup.object().shape({
  ...envVarsSchema.fields,
  image: Yup.string().required('Image is required'),
  adminAddress: Yup.string().required('Admin address is required'),
  adminPort: Yup.number().required('Admin port is required'),
  resources: resourcesSchema,
});

export const RemoteParticipantCreateSchema = Yup.object().shape({
  ...createRemoteSchema.fields,
  name: getNameValidation('Remote participant'),
  ledgerAddress: Yup.string().required('Ledger address is required'),
  ledgerId: Yup.string().required('Ledger id is required'),
  ledgerPort: Yup.number().required('Ledger port is required'),
  jsonApiUrl: Yup.string().required('JSON API URL is required'),
});

export const DomainCreateSchema = Yup.object().shape({
  ...createSchema.fields,
  name: getNameValidation('Domain'),
  publicPort: Yup.number().required('Public port is required'),
});

export const RemoteDomainCreateSchema = Yup.object().shape({
  ...createRemoteSchema.fields,
  name: getNameValidation('Remote domain'),
  publicPort: Yup.number().required('Public port is required'),
  publicAddress: Yup.string().required('Public address is required'),
});

export const AppCreateSchema = Yup.object().shape({
  ...envVarsSchema.fields,
  name: getNameValidation('Application'),
  domain: Yup.string(),
  type: Yup.string(),
  image: Yup.string()
    .test('Image should not be empty when type is Backend', REQUIRED_FIELD_ERROR_MESSAGE, function (value, context) {
      const type = context?.from?.[0].value.type;
      if (type === ApplicationDto.type.BACKEND && !value) return false;

      return true;
    })
    .test('File or image', 'Choose image or file', function (value, context) {
      const type = context?.from?.[0].value.type;
      const file = context?.from?.[0].value.packageName;
      if (type === ApplicationDto.type.UI && !value && !file) return false;
      if (type === ApplicationDto.type.UI && value && file) return false;

      return true;
    }),
  packageName: Yup.string().test('File or image', 'Choose image or file', function (value, context) {
    const type = context?.from?.[0].value.type;
    const image = context?.from?.[0].value.image;
    if (type === ApplicationDto.type.UI && !value && !image) return false;
    if (type === ApplicationDto.type.UI && value && image) return false;

    return true;
  }),
  port: Yup.number().typeError('Port value must be a number'),
  resources: resourcesSchema,
});

export const ParticipantCreateSchema = Yup.object().shape({
  ...createSchema.fields,
  name: getNameValidation('Participant'),
  ledgerPort: Yup.number().required('Ledger port is required'),
  jsonapi: Yup.boolean(),
  navigator: boolean(),
  dars: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
  ),
  auth: Yup.boolean(),
});

export const ParticipantUploadDarSchema = Yup.object().shape({
  darName: Yup.string().test('DAR File', 'Select the DAR file to upload.', function (value, context) {
    const file = context?.from?.[0].value.darName;
    if (!file || !value) return false;

    return true;
  }),
});

const isValid = (value) => value.split(' ').join('').length !== 0;

const rightsSchema = Yup.object().shape({
  rights: Yup.array().of(
    Yup.object().shape({
      type: Yup.string()
        .ensure() // Transforms undefined and null values to an empty string.
        .test('Type empty?', 'Type cannot be empty', (value) => {
          return isValid(value);
        }),
      party: Yup.string()
        .ensure()
        .test('Party empty?', 'Party cannot be empty', (value, ctx) => {
          if (ctx.parent.type !== 'ParticipantAdmin') {
            return isValid(value);
          }
          return true;
        }),
    }),
  ),
});

export const UserCreateSchema = Yup.object().shape({
  userId: Yup.string().required('Name is required'),
  primaryParty: Yup.string(),
  ...rightsSchema.fields,
});

export const UserEditRightsSchema = Yup.object().shape({
  ...rightsSchema.fields,
});

export const ParticipantEditSchema = Yup.object().shape({
  image: Yup.string().required('Image is required'),
  resources: resourcesSchema,
  ...envVarsSchema.fields,
});

export const DomainEditSchema = Yup.object().shape({
  image: Yup.string().required('Image is required'),
  resources: resourcesSchema,
  ...envVarsSchema.fields,
});

export const ApplicationEditSchema = Yup.object().shape({
  resources: resourcesSchema,
});

export const ParticipantConnectSchema = Yup.object().shape({
  name: Yup.string(),
  domainAlias: Yup.string().required('Domain alias is required'),
  domainName: Yup.string().when('custom', {
    is: false,
    then: () => Yup.string().required('Domain name is required'),
  }),
  domainUrl: Yup.string().when('custom', { is: true, then: () => Yup.string().required('Domain URL is required') }),
  publicPort: Yup.string(),
  connected: Yup.boolean().nullable(),
  port: Yup.number(),
});

export const PartyCreateSchema = Yup.object().shape({
  displayName: Yup.string()
    .matches(
      nameRegExp,
      "Party name is not valid. Name must consist of lower case alphanumeric characters (a-z) or '-', or '.', and must start with an alphanumeric character",
    )
    .required('Party name is required'),
});

export const SchedulePruningSchema = Yup.object().shape({
  cron: Yup.string()
    .test('Cron valid??', 'Cron must be valid', (_, ctx) => {
      const cronOptions = ctx.parent.enterprise
        ? {
            ...enterpriseCronOptions,
            useYears: ctx.parent.year,
          }
        : {
            useAliases: true,
            useBlankDay: true,
          };

      const cronResult = cron(ctx.originalValue as string, {
        override: cronOptions,
      });

      if (cronResult.isValid()) {
        return true;
      }

      const errorsSet = new Set(cronResult.getError());

      return ctx.createError({
        message: Array.from(errorsSet).join(', '),
        path: ctx.path,
      });
    })
    .required('Cron is required'),
  retentionInSec: Yup.number().integer().min(1).required('Retention value is required'),
  maxDurationInSec: Yup.number().integer().min(1).required('Max duration value is required'),
});

export const MediatorSchedulePruningSchema = Yup.object().shape({
  mediator: Yup.object().shape({
    ...SchedulePruningSchema.fields,
  }),
});

export const SequencerSchedulePruningSchema = Yup.object().shape({
  sequencer: Yup.object().shape({
    ...SchedulePruningSchema.fields,
  }),
});

export const ScheduleBackupSchema = Yup.object().shape({
  cron: Yup.string()
    .test('Is cron valid?', 'Cron must be valid', (_, ctx) => {
      const cronResult = cron(ctx.originalValue as string, {
        override: backupScheduleCronOptions,
      });

      if (cronResult.isValid()) {
        return true;
      }

      const errorsSet = new Set(cronResult.getError());

      return ctx.createError({
        message: Array.from(errorsSet).join(', '),
        path: ctx.path,
      });
    })
    .required(
      'Cron is required. Syntax: minute (0-59), hour (0-23), day of the month (1-31), month (1-12, JAN to DEC), day of the week (0-6, Sunday to Saturday OR sun, mon, tue, wed, thu, fri, sat)',
    ),
  maxBackups: Yup.number()
    .integer('The value should be integer')
    .min(1, 'The value should be greater than or equal to 1')
    .required('Max Backups field is required'),
});
